import { Title } from "emoreg/atoms/Title";
import { Typography } from "emoreg/atoms/Typography";
import { Flex } from "emoreg";
import { useSessionCollaboratorContext } from "inside-components";
import styled from "styled-components";

const Error404Container = styled.div`
  text-align: center;
  max-width: 550px;
  padding: 16px 48px;
  align-self: center;
`;

export const Error404 = ({ mainText = null }: { mainText?: string | null }) => {
  const { sessionCollaborator } = useSessionCollaboratorContext();
  const { myAccessibleLegalEntities } = sessionCollaborator;
  const hasMultipleLegalEntityAvailableText = myAccessibleLegalEntities.length > 1;
  return (
    <Error404Container>
      <Flex direction="column" gap={16}>
        <img
          style={{ objectFit: "contain", width: "100%", height: "100%" }}
          src="https://s3.eu-west-3.amazonaws.com/prod.immutable.ouihelp.fr-20240807102053703500000003/error-404.svg"
          alt="404 Error"
        />
        <Title type="h2">Cette page n’est pas accessible</Title>
        <Flex direction="column" gap={32}>
          {mainText && <Typography>{mainText}</Typography>}
          {hasMultipleLegalEntityAvailableText && (
            <Typography>
              Il se peut que cette page ne soit accessible que via une autre entreprise. Changez
              d’entreprise puis rechargez cette page.
            </Typography>
          )}
          <Typography>
            S’il s’agit d’un bug, contactez <a href="mail:bugs@ouihelp.fr">bugs@ouihelp.fr</a>.
          </Typography>
        </Flex>
      </Flex>
    </Error404Container>
  );
};
