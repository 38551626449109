import { Spaces } from "emoreg/const";
import { ResponsivePropsOrSingleObject } from "emoreg/types";
import React, { CSSProperties } from "react";
import styled from "styled-components";
import { Box, BoxProps } from "./Box";
import { forTabletUp } from "emoreg/utils/responsive";
import {
  extractBreakpointFromResponsiveProps,
  toResponsiveProps,
} from "emoreg/utils/responsive-props";

type FlexApiProps = Partial<{
  direction: CSSProperties["flexDirection"];
  wrap: CSSProperties["flexWrap"];
  basis: CSSProperties["flexBasis"];
  grow: CSSProperties["flexGrow"];
  shrink: CSSProperties["flexShrink"];
  alignItems: CSSProperties["alignItems"];
  justifyContent: CSSProperties["justifyContent"];
  alignContent: CSSProperties["alignContent"];
  alignSelf: CSSProperties["alignSelf"];
  gap: Spaces;
}>;

const flexApi = (props: FlexApiProps) => ({
  flexDirection: props.direction,
  flexWrap: props.wrap,
  flexBasis: props.basis,
  flexGrow: props.grow,
  flexShrink: props.shrink,
  alignItems: props.alignItems,
  justifyContent: props.justifyContent,
  alignContent: props.alignContent,
  alignSelf: props.alignSelf,
  gap: props.gap,
});

export type FlexProps = ResponsivePropsOrSingleObject<FlexApiProps> & BoxProps;

export const Flex: React.FC<FlexProps> = styled(Box)<FlexProps>(
  { display: "flex" },
  props => extractBreakpointFromResponsiveProps(toResponsiveProps(flexApi(props)), "mobile"),
  forTabletUp(props =>
    extractBreakpointFromResponsiveProps(toResponsiveProps(flexApi(props)), "tablet")
  )
);

Flex.displayName = "Flex";
