import * as router from "hassibot/internal-router";
import { get, set } from "hassibot/local-storage";
import { useSessionCollaboratorContext } from "inside-components";
import React, { useContext, useLayoutEffect, useState } from "react";
import { indexedDBCache } from "./services_v2/common/cache";
import { LegalEntity, LegalMode } from "./services_v2/common/types";

export const CURRENT_LEGAL_ENTITY_LOCAL_STORAGE_KEY = "collaborator-current-legal-entity-uuid";
export const CURRENT_LEGAL_MODE_LOCAL_STORAGE_KEY = "collaborator-current-legal-mode";

const REDIRECTION_LIST_VIEWS = [
  router.proclientAppRouter.sharedHouseList(),
  router.proclientAppRouter.list(),
  router.caregiverAppRouter.broadcastList(),
  router.caregiverAppRouter.list(),
  router.prescriberAppRouter.structureList(),
  router.prescriberAppRouter.list(),
  router.picoAppRouter.list(),
  router.badgeAppRouter.badges(),
  router.monetAppRouter.listBills(),
  router.monetAppRouter.listRegularizations(),
  router.monetAppRouter.listPayments(),
  router.monetAppRouter.listProclientsMembersPaymentsIntents(),
  router.monetAppRouter.listCaregiversPaymentsIntents(),
  router.monetAppRouter.listTaxCreditPaymentRequests(),
  router.monetAppRouter.listThirdPartyPayers(),
  router.compassAppRouter.root(),
];

interface CurrentLegalEntityContextType {
  currentLegalEntity: LegalEntity;
  setCurrentLegalEntity: (newLegalEntity: LegalEntity) => void;
}

const CurrentLegalEntityContext = React.createContext<CurrentLegalEntityContextType | undefined>(
  undefined
);

export const CurrentLegalEntityProvider = ({ children }) => {
  const { sessionCollaborator } = useSessionCollaboratorContext();

  const { myAccessibleLegalEntities } = sessionCollaborator;

  const getDefaultLegalEntity = (): LegalEntity => {
    if (myAccessibleLegalEntities.length === 0) {
      throw new Error("No legal entity found for the current user");
    }

    // Get initial value from local storage or arbitrarily use the first value from the given list
    const legalEntityUuidFromLocalStorage = get(CURRENT_LEGAL_ENTITY_LOCAL_STORAGE_KEY);
    const legalModeFromLocalStorage = get(CURRENT_LEGAL_MODE_LOCAL_STORAGE_KEY);
    const currentLegalEntity =
      myAccessibleLegalEntities.find(
        legalEntity =>
          legalEntityUuidFromLocalStorage === legalEntity.uuid &&
          legalModeFromLocalStorage === legalEntity.mode
      ) ?? myAccessibleLegalEntities[0];

    return currentLegalEntity;
  };

  const [legalEntity, setLegalEntity] = useState<LegalEntity>(getDefaultLegalEntity);

  useLayoutEffect(() => {
    indexedDBCache.initLegalEntityDatabase(legalEntity);
  }, [legalEntity]);

  useLayoutEffect(() => {
    const legalEntityUuidFromLocalStorage = get(CURRENT_LEGAL_ENTITY_LOCAL_STORAGE_KEY);
    const legalModeFromLocalStorage = get(CURRENT_LEGAL_MODE_LOCAL_STORAGE_KEY);
    // if legal entity changed, force page refresh
    if (
      legalEntityUuidFromLocalStorage !== legalEntity.uuid ||
      legalModeFromLocalStorage !== legalEntity.mode
    ) {
      // set new legal entity in local storage
      set(CURRENT_LEGAL_ENTITY_LOCAL_STORAGE_KEY, legalEntity.uuid);
      set(CURRENT_LEGAL_MODE_LOCAL_STORAGE_KEY, legalEntity.mode);
      // compute redirection url
      const path = window.location.pathname.split("?")[0];
      const closestListView = REDIRECTION_LIST_VIEWS.find(listView => {
        return path.startsWith(listView);
      });
      // refresh page using found matching list view or current view
      if (closestListView) window.location.href = closestListView;
      else window.location.reload();
    }
  }, [legalEntity]);

  return (
    <CurrentLegalEntityContext.Provider
      value={{ currentLegalEntity: legalEntity, setCurrentLegalEntity: setLegalEntity }}
    >
      {children}
    </CurrentLegalEntityContext.Provider>
  );
};

export const useLegalMode = () => {
  const context = useContext(CurrentLegalEntityContext);

  if (!context) {
    throw new Error(
      "The application context is missing, add 'CurrentLegalEntityProvider' at the top of component hierarchy"
    );
  }

  const { currentLegalEntity, setCurrentLegalEntity } = context;

  const isMandatary = currentLegalEntity.mode === LegalMode.MANDATARY;
  const isContractor = currentLegalEntity.mode === LegalMode.CONTRACTOR;

  return {
    mode: currentLegalEntity.mode,
    isMandatary,
    isContractor,
    currentLegalEntity,
    setCurrentLegalEntity,
  };
};
