import { backgroundColor, color, font, spaces } from "emoreg/const";
import { css } from "styled-components";
import {
  BaseNavigation,
  NavigationWithRouterItem,
  NavItem,
  RouteMatchFn,
  RouterNavigation,
} from "styles/molecules/nav-bar/nav";
import { responsiveHelpers as rh } from "styles/utils";

const NAV_MAIN_STYLE = css`
  border-bottom: 0.5px solid ${color.grey.base};
  margin-bottom: ${spaces[32]};
  margin-top: ${spaces[24]};
  ${rh.belowLandscapeTablet`
      overflow-x: auto;
      overflow-y: hidden;
  `}
`;
const LIST_MAIN_STYLE = css`
  position: relative;
  display: flex;
  padding: 0 ${spaces[16]};
  list-style: none;
  max-width: 1350px;
  margin: 0 auto -1px auto;
  width: 100%;
`;
const ITEM_MAIN_STYLE = css`
  margin-bottom: 0;
  color: ${color.grey.dark};
  padding: ${spaces[8]} ${spaces[16]};
  margin-right: ${spaces[12]};
  border: 1px solid transparent;
  border-top: 3px solid transparent;
  border-radius: 4px 4px 0 0;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    color: ${color.grey.darker};
    text-decoration: none;
  }
`;
const ITEM_MAIN_STYLE_ACTIVE = (type: "white" | "blue") => css`
  ${({ isActive }: { isActive: boolean }) => {
    if (!isActive) {
      return null;
    }
    switch (type) {
      case "white":
        return css`
          border-color: ${color.blue.base} ${color.grey.base} transparent;
          color: ${color.blue.base};
          font-weight: ${font.weight.semiBold};
          background-color: #fff;
          &:hover {
            color: ${color.blue.base};
          }
        `;
      case "blue":
        return css`
          border-color: ${color.blue.base} ${color.grey.base} transparent;
          color: ${color.blue.base};
          font-weight: ${font.weight.semiBold};
          background-color: ${backgroundColor};
          &:hover {
            color: ${color.blue.base};
          }
        `;
    }
  }}
`;

export const MainRouterNavigation = ({
  activeItemStyleType,
  ...props
}: {
  navItems: NavigationWithRouterItem[];
  routeMatch?: RouteMatchFn;
  noMatchRedirectTo?: string;
  activeItemStyleType?: "white" | "blue";
}) => (
  <RouterNavigation
    navStyle={NAV_MAIN_STYLE}
    listStyle={LIST_MAIN_STYLE}
    itemStyle={ITEM_MAIN_STYLE}
    activeItemStyle={ITEM_MAIN_STYLE_ACTIVE(activeItemStyleType || "blue")}
    {...props}
  />
);

export const MainNavigation = ({
  activeItemStyleType,
  ...props
}: {
  navItems: NavItem[];
  activeItemStyleType?: "white" | "blue";
}) => (
  <BaseNavigation
    navStyle={NAV_MAIN_STYLE}
    listStyle={LIST_MAIN_STYLE}
    itemStyle={ITEM_MAIN_STYLE}
    activeItemStyle={ITEM_MAIN_STYLE_ACTIVE(activeItemStyleType || "blue")}
    {...props}
  />
);
